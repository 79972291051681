import $ from "jquery";
import "jquery-ui/ui/widgets/sortable";

import gettext from "../../rainbow/js/gettext";
import isValidUrl from "../../rainbow/js/valid-url";
import ProfileAgent from "../../rainbow/js/widgets/profile";
import {showError} from "./message";
import Page from "./page";

export default class LinksPage extends Page {
    constructor() {
        super();

        this.ui = this.findElements({
            noLinks: ".no-links",
            links: ".links",
            linkTemplate: ".template li:first",
            linkForm: {
                action: ".action",
                url: ".url",
                title: ".title",
                save: ".submit",
                cancel: ".cancel",
            },
            perPage: ".links-per-page select",
        });

        this.on({
            "click .submit": () => this.saveLink(),
            "click .cancel": () => this.cancel(),
            "click .links .delete": e => this.deleteLink(e),
            "click .links .edit": e => this.editLink(e),
            "keydown .title": (e) => {
                if (e.which === 13) {
                    this.saveLink();
                }
            }
        });

        this.ui.links.sortable({
            change: () => this.hasChanges = true,
        });
        this.editLinkId = undefined;

        ProfileAgent.onChange("links", (e, links) => {
            this.links = links || [];
            this.renderLinks();
        });

        ProfileAgent.onChange("links_per_page", (e, linksPerPage) => {
            // 'linksPerPage' got from the user profile.
            // 'selectedValue'(ui.perPage) is value came from server ('max_number_of_links').
            // if 'linksPerPage' is greater then 'selectedValue', we need to reassign 'ui.perPage'
            // to make 'selectedValue' active and not leave the dropdown empty.
            const selectedValue = this.ui.perPage.val();

            if (linksPerPage && selectedValue < linksPerPage) {
                this.ui.perPage.val(selectedValue);
            } else {
                this.ui.perPage.val(linksPerPage || 5);
            }
        });
    }

    renderLinks() {
        this.ui.links.empty();

        if (this.links.length === 0) {
            this.ui.noLinks.show();
            return;
        }

        this.ui.noLinks.hide();

        for (let i = 0; i < this.links.length; i += 1) {
            const item = this.ui.linkTemplate.clone();

            // If href starts with # return window.origin and URL as it's a search overview page
            const url = this.links[i].url.substring(0, 1) === "#" ? this.changeLocalLinks(this.links[i].url) : this.links[i].url;

            item.find(".link").attr("href", url);
            item.find(".link").text(this.links[i].title);
            item.find(".link").data("id", this.links[i].id);
            item.find(".link").data("trackingId", this.links[i].tracking_id);
            item.appendTo(this.ui.links);
        }
    }

    changeLocalLinks(url) {
        return `${window.location.origin}/${url}`;
    }

    getLinkData() {
        const title = this.ui.linkForm.title.val();
        let url = this.ui.linkForm.url.val();

        if (!/^(http|https|ftp|ftps):\/\//i.test(url)) {
            url = "http://" + url;
        }

        return {title: title, url: url};
    }

    validateLink() {
        const link = this.getLinkData();
        const errors = [];

        if (!link.title || !link.url || link.url.replace(/https?:\/\//, "") === "") {
            errors.push(gettext("Enter a description and URL"));
        } else if (!isValidUrl(link.url)) {
            errors.push(gettext("The entered URL is incomplete or not valid."));
        }

        if (errors.length) {
            showError(errors);
        }

        return errors.length === 0;
    }

    saveLink() {
        if (!this.validateLink()) {
            return;
        }

        const link = this.getLinkData();
        if (this.editLinkId === undefined) {
            this.links.push(link);
        } else {
            for (let i = 0; i < this.links.length; i += 1) {
                if (this.links[i].id === this.editLinkId) {
                    this.links[i].title = link.title;
                    this.links[i].url = link.url;
                    break;
                }
            }
        }

        this.cancel();
        this.renderLinks();
    }

    deleteLink(e) {
        const target = $(e.target),
            item = target.closest("li"),
            linkId = item.find(".link").data("id");

        this.hasChanges = true;
        this.links = this.links.filter(link => link.id !== linkId);
        this.renderLinks();
    }

    editLink(e) {
        const target = $(e.target),
            item = target.closest("li"),
            url = item.find(".link").attr("href");

        if (item.find(".link").data("trackingId")) {
            this.ui.linkForm.url.prop("disabled", true);
        } else {
            this.ui.linkForm.url.prop("disabled", false);
        }

        this.editLinkId = item.find(".link").data("id");
        this.ui.linkForm.url.val(url);
        this.ui.linkForm.title.val(item.find(".link").text());

        this.ui.linkForm.action.text(gettext("edit"));
        this.ui.linkForm.save.text(gettext("Save"));
    }

    cancel() {
        this.ui.linkForm.cancel.hide();
        this.ui.linkForm.save.show();
        this.ui.linkForm.url.val("");
        this.ui.linkForm.title.val("");

        this.ui.linkForm.action.text(gettext("add"));
        this.ui.linkForm.save.text(gettext("Add"));

        this.editLinkId = undefined;
    }

    getReorderedLinks() {
        // This function applies the display ordering to the internal list of links.
        return this.ui.links.find(".link").map((i, item) => {
            const id = $(item).data("id");
            return this.links.filter(link => link.id === id);
        }).get();
    }

    getData() {
        this.links = this.getReorderedLinks();

        return {
            links: this.links,
            links_per_page: parseInt(this.ui.perPage.val(), 10),
        };
    }

    validate() {
        return true;
    }
}
