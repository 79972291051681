import {EmailVerificationWidget} from "authwidgets";

import getConfig from "../../umbrella/js/config";

const EmailVerificationPage = () => ({
    init() {
        const params = {
            "apiUrl": getConfig("authserviceApiUrl"),
            "loginUrl": getConfig("loginUrl"),
            "profileUrl": getConfig("profileUrl"),
            "returnUrl": getConfig("emailVerificationUrl"),
            "showLabels": true,
            "showDisplayName": true,
            "showGender": true,
            "siteName": "Startpagina.nl",
            "optinName": "startpagina",
            "recaptcha": {
                siteKey: getConfig("recaptchaSiteKey")
            },
            "profileInfo": getConfig("profileData", {}),
        };

        const widgetElement = document.querySelector(".verification-widget");
        this.verificationWidget = new EmailVerificationWidget(widgetElement, params);
        this.verificationWidget.inject();
        this.verificationWidget.onStateChanged = (oldState, newState) => {
            if (newState === "success") {
                this.$dispatch("email-verified");
            }
        };

        const widgetContainer = document.querySelector(".verification-container");
        widgetContainer.classList.remove("hidden");
    }

});

export default EmailVerificationPage;
