import getConfig from "../../umbrella/js/config";

const DeleteAccountButton = () => ({

    openDeleteAccountDialog() {
        this.$refs.dialog.showModal();
    },

    closeDeleteAccountDialog(ev) {
        if (ev.target.id !== "deleteAccountButton") {
            this.$refs.dialog.close();
        }
    },

    deleteAccount() {
        const deleteUserUrl = new URL("/user/me/", getConfig("authserviceApiUrl"));
        const options = {
            method: "DELETE",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            referrerPolicy: "no-referrer-when-downgrade",
        };

        fetch(deleteUserUrl, options).then(() => {
            window.location.href = getConfig("loginUrl");
        }).catch(error => {
            console.error(error);
        });

    },

});

export default DeleteAccountButton;
