import $ from "jquery";

import gettext from "../../rainbow/js/gettext";
import ProfileAgent from "../../rainbow/js/widgets/profile";
import {showError, showMessage} from "./message";

const unsavedChangesUnloadMessage = gettext("Your changes on this page have not been saved.\n\n" +
    "Stay on this page to save your changes with the \"Save\" button.\n" +
    "If you leave this page, your changes will be discarded.");

const unsavedChangesBackMessage = gettext("Your changes on this page have not been saved.\n\n" +
    "Choose \"Cancel\" to be able to save your changes with the \"Save\" button.\n" +
    "Choose \"OK\" to leave this page.");

export default class Page {
    constructor() {
        this.element = $(".profile.row");
        window.onbeforeunload = e => this.beforeUnload(e);
        this.hasChanges = false;

        this.on({
            "click .save-or-return .button-back": e => this.backToHome(e),
            "click .save-or-return .save": () => this.save(),
            "change input,select": () => this.hasChanges = true,
        });
    }

    on(events) {
        for (const spec of Object.keys(events)) {
            const [, event, selector] = spec.match(/(\S+)(?:\s+(.*))?/);
            if (selector) {
                this.element.on(event, selector, events[spec]);
            } else {
                this.element.on(event, events[spec]);
            }
        }
    }

    findElements(selectors, parent) {
        const elements = {};

        parent = parent || this.element;

        for (const key of Object.keys(selectors)) {
            const selector = selectors[key];
            if (typeof selector === "string") {
                elements[key] = parent.find(selector);
                if (elements[key].length === 0 && window.console) {
                    // eslint-disable-next-line no-console
                    console.error(`Unable to find UI element ${key} with selector ${selector} in`, parent);
                }
            } else {
                let newParent = parent;
                if (selector.parent) {
                    newParent = parent.find(selector.parent);
                    delete selector.parent;
                }
                elements[key] = this.findElements(selector, newParent);
                elements[key].parent = newParent;
            }
        }

        return elements;
    }

    validate() {
        return true;
    }

    getData() {
        throw new Error("getData() is not implemented");
    }

    beforeUnload(e) {
        if (this.hasChanges) {
            e.returnValue = unsavedChangesUnloadMessage;
            return unsavedChangesUnloadMessage;
        }

        return undefined;
    }

    backToHome(e) {
        if (this.hasChanges && !confirm(unsavedChangesBackMessage)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    save(extraData = {}) {
        return new Promise((resolve, reject) => {
            if (!this.validate()) {
                reject();
                return;
            }

            ProfileAgent.set(Object.assign(this.getData(), extraData)).then(
                () => {
                    this.hasChanges = false;
                    showMessage(gettext("Your changes have been saved"));
                    resolve();
                },
                () => {
                    showError(gettext(
                        "There was an error while trying to save your changes. " +
                        "Please try again later."
                    ));
                    reject();
                }
            );
        });
    }
}
