import $ from "jquery";

const defaultContainer = $("#message-region");

export function showMessage(message, container, messageType) {
    const messageBox = $("<div/>").addClass("message");

    if (messageType) {
        messageBox.addClass(messageType);
    }

    const close = $("<a />").addClass("close").html("&times;").appendTo(messageBox);

    close.on("click", function (e) {
        messageBox.remove();
        e.preventDefault();
    });

    if (Array.isArray(message)) {
        for (const msg of message) {
            $("<p />").text(msg).appendTo(messageBox);
        }
    } else {
        $("<p />").text(message).appendTo(messageBox);
    }


    $(container || defaultContainer).append(messageBox);
}

export function showError(message, container) {
    showMessage(message, container, "error");
}
