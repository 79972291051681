import {RegistrationWidget} from "authwidgets";

import getConfig from "../../umbrella/js/config";

const RegistrationPage = () => ({
    isSubmitting: false,
    isRegistrationSuccessful: false,
    isLoggedIn: false,
    emailUsed: "",
    mandatoryOption: false,
    optionalOption: false,

    init() {
        const params = {
            "apiUrl": getConfig("authserviceApiUrl"),
            "showLabels": true,
            "showDisplayName": false,
            "showGender": true,
            "siteName": "Startpagina.nl",
            "termsConditionsUrl": getConfig("termsConditionsUrl"),
            "privacyCookiesUrl": getConfig("privacyCookiesUrl"),
            "optinName": "startpagina",
            "recaptcha": {
                siteKey: getConfig("recaptchaSiteKey")
            },
            "profileInfo": getConfig("profileData", {}),
            "returnUrl": getConfig("emailVerificationUrl") + "/#token=",
        };

        const registrationWidgetElement = document.querySelector(".registration-widget");
        this.registrationWidget = new RegistrationWidget(registrationWidgetElement, params);
        this.registrationWidget.inject();

        const registrationWidgetContainer = document.querySelector(".authwidgets_registration-container");
        registrationWidgetContainer.classList.remove("hidden");

        this.bindEnterToRegister();
    },

    bindEnterToRegister() {
        const inputs = document.querySelectorAll(".authwidgets_registration-container input:not([type='checkbox'])");

        inputs.forEach((input) => {
            input.addEventListener("keyup", (event) => {
                if (event.key === "Enter") {
                    this.register();
                }
            });
        });
    },
    async register() {
        this.isSubmitting = true;
        const result = await this.registrationWidget.registerOrUpdate();
        if(result.success) {
            this.isRegistrationSuccessful = result.success;
            this.isSubmitting = false;
            this.mandatoryOption = true;
        }
    },

});

export default RegistrationPage;
