import Alpine from "alpinejs";

import "../../rainbow/js/index";
import AccountButton from "./account-button";
import DeleteAccountButton from "./delete-account-button";
import EmailVerificationPage from "./email-verification-page";
import RegistrationPage from "./registration-page";

Alpine.data("AccountButton", AccountButton);
Alpine.data("EmailVerificationPage", EmailVerificationPage);
Alpine.data("RegistrationPage", RegistrationPage);
Alpine.data("DeleteAccountButton", DeleteAccountButton);
