import gettext from "../../rainbow/js/gettext";
import ProfileAgent from "../../rainbow/js/widgets/profile";
import {showError} from "./message";
import Page from "./page";

export default class PrivacyPage extends Page {
    constructor() {
        super();

        this.ui = this.findElements({
            enableChildLock: "#child-lock-enabled",
            newPassword: {
                fields: "#child-lock-password-fieldset",
                password: "#child-lock-password",
                passwordRepeat: "#child-lock-password-repeat",
            },
            currentPassword: {
                fields: "#child-lock-current-password-fieldset",
                password: "#child-lock-current-password",
            },
            consentPartners: {
                anchor: ".consent-partners",
            },
        });

        this.on({
            "click #child-lock-enabled": () => this.toggleFields(),
            "click .consent-partners": () => this.showConsentPartners(),
        });

        ProfileAgent.onChange("child_lock", (e, childLock) => {
            this.currentPassword = childLock;
            this.ui.enableChildLock.prop("checked", !!childLock);
            this.toggleFields();
        });
    }

    showConsentPartners() {
        // eslint-disable-next-line no-undef
        googlefc.callbackQueue.push({"CONSENT_DATA_READY": () => googlefc.showRevocationMessage()});
    }

    toggleFields() {
        this.ui.newPassword.password.val("");
        this.ui.newPassword.passwordRepeat.val("");

        this.ui.currentPassword.fields.toggle(!!this.currentPassword);
        this.ui.newPassword.fields.toggle(this.ui.enableChildLock.prop("checked"));
    }

    validate() {
        const enabled = !!this.currentPassword;
        const errors = [];

        // If the child lock is currently enabled, we need to check the current password.
        if (enabled && this.currentPassword !== this.ui.currentPassword.password.val()) {
            errors.push(gettext("The entered password is incorrect"));
        }

        // If the child lock is being enabled or changed, we need to validate the new password.
        if (this.ui.enableChildLock.prop("checked")) {
            const password = this.ui.newPassword.password.val();
            const passwordRepeat = this.ui.newPassword.passwordRepeat.val();

            if (password === "" || passwordRepeat === "") {
                errors.push(gettext("Enter a new password"));
            } else if (password !== passwordRepeat) {
                errors.push(gettext("The entered passwords do not match"));
            }
        }

        if (errors.length) {
            showError(errors);
        }

        return errors.length === 0;
    }

    getData() {
        const password = this.ui.newPassword.password.val();
        const enabled = this.ui.enableChildLock.prop("checked");

        return {
            child_lock: enabled ? password : "",
        };
    }
}
