import $ from "jquery";

import "../../rainbow/js/background-color";
import ProfileAgent from "../../rainbow/js/widgets/profile";
import Page from "./page";

export default class DisplayPage extends Page {
    constructor() {
        super();

        this.ui = this.findElements({
            swatch: ".swatch",
        });

        this.on({
            "click .swatch": e => this.selectColor(e),
        });

        this.background = "";

        ProfileAgent.on("login", () => {
            this.setBackground(ProfileAgent.profile.data.background);
        });
    }

    setBackground(background) {
        this.ui.swatch.removeClass("selected");
        this.ui.swatch.filter(`[data-value="${background}"]`).addClass("selected");

        // This duplicates the code from `rainbow/js/background-color`, but on
        // this page we want the background color to change immediately when
        // the selection changes.
        if (this.background) {
            $("body").removeClass(`bg-${this.background}`);
        }
        if (background) {
            $("body").addClass(`bg-${background}`);
        }

        this.background = background;
    }

    selectColor(e) {
        e.preventDefault();
        this.setBackground($(e.target).data("value"));
        this.hasChanges = true;
    }

    getData() {
        return {
            background: this.background,
        };
    }
}
