import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";

import gettext from "../../rainbow/js/gettext";
import {Location, LocationObj} from "../../rainbow/js/widgets/location";
import ProfileAgent from "../../rainbow/js/widgets/profile";
import {showError} from "./message";
import Page from "./page";

export default class LocationPage extends Page {
    constructor() {
        super();

        this.ui = this.findElements({
            allowLocation: "#allow-location",
            locations: {
                parent: ".locations",
                default: ".default",
                empty: ".empty",
                template: ".template",
            },
            add: {
                parent: "#add-location-form",
                location: ".add-location",
                title: ".add-location-title",
            }
        });

        this.on({
            "click .location-auto": () => this.automaticLocation(),
            "click .location-delete": e => this.deleteLocation(e),
            "click .location-add": () => this.saveLocation(),
        });

        ProfileAgent.onChange("locations", (e, locations) => {
            this.locations = locations.map(location => new LocationObj(location)) || [];
            this.renderLocations();
        });

        ProfileAgent.onChange("allow_location", (e, allowLocation) => {
            this.ui.allowLocation.prop("checked", allowLocation);
        });

        this.ui.add.location.autocomplete({
            source: (request, response) => this.autocomplete(request, response),
        });
    }

    renderLocations() {
        this.ui.locations.parent.find(".location").remove();

        if (this.locations.length === 0) {
            this.ui.locations.default.hide();
            this.ui.locations.empty.show();
            return;
        }

        this.ui.locations.default.show();
        this.ui.locations.empty.hide();

        for (const location of this.locations) {
            const item = this.ui.locations.template.clone();
            item.appendTo(this.ui.locations.parent);
            item.attr("class", "location");
            item.data("location", location.location);
            item.find(".title").text(location.toString());
        }
    }

    automaticLocation() {
        navigator.geolocation.getCurrentPosition(
            position => {
                const query = {
                    lat: position.coords.latitude,
                    lon: position.coords.longitude
                };

                Location.getLocation(query).then(data => {
                    if (data.length !== 0) {
                        const locality = data[0].locality;
                        if (locality) {
                            this.ui.add.location.val(locality);
                            this.hasChanges = true;
                        }
                    } else {
                        showError(gettext("Unable to detect your location automatically."));
                    }
                });
            },
            () => {
                showError(gettext(
                    "Unable to detect your location automatically. " +
                    "You will have to allow Startpagina to access your location in your browser settings."
                ));
            },
            {enableHighAccuracy: true}
        );
    }

    deleteLocation(e) {
        const target = $(e.target),
            item = target.closest("li"),
            location = item.data("location");

        this.hasChanges = true;
        this.locations = this.locations.filter(l => l.location !== location);
        this.renderLocations();
    }

    saveLocation() {
        const location = this.ui.add.location.val();
        const title = this.ui.add.title.val();

        if (!location) {
            showError(gettext("Please enter a location"));
            return;
        }

        const query = {locality: location};

        Location.getLocation(query).then(data => {
            if (data.length !== 0) {
                const location = data[0];
                if (location.lat && location.lon) {
                    this.locations.push(new LocationObj({
                        location: location.locality,
                        title: title,
                        lat: parseFloat(location.lat).toFixed(7),
                        lon: parseFloat(location.lon).toFixed(7),
                    }));
                    this.renderLocations();
                }
            } else {
                showError(gettext("Unable to find the entered location."));
            }
        });
    }

    autocomplete(request, response) {
        Location.getLocationsByQuery(request.term)
            .then(rows => response(rows.items))
            .catch(() => response([]));
    }

    getData() {
        return {
            allow_location: this.ui.allowLocation.prop("checked"),
            locations: this.locations,
        };
    }
}
