import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker";

import gettext, {pgettext} from "../../rainbow/js/gettext";
import {emailRegexp} from "../../rainbow/js/validation/validation-checks";
import ProfileAgent from "../../rainbow/js/widgets/profile";
import {showError, showMessage} from "./message";
import Page from "./page";

const monthNames = [
    gettext("January"),
    gettext("February"),
    gettext("March"),
    gettext("April"),
    gettext("May"),
    gettext("June"),
    gettext("July"),
    gettext("August"),
    gettext("September"),
    gettext("October"),
    gettext("November"),
    gettext("December"),
];
const dayNames = [
    pgettext("abbreviated day", "Sun"),
    pgettext("abbreviated day", "Mon"),
    pgettext("abbreviated day", "Tue"),
    pgettext("abbreviated day", "Wed"),
    pgettext("abbreviated day", "Thu"),
    pgettext("abbreviated day", "Fri"),
    pgettext("abbreviated day", "Sat"),
];

export function dateToServerString(date) {
    return date ? $.datepicker.formatDate("yy-mm-dd", date) : null;
}

export function dateFromServerString(dateString) {
    return dateString ? $.datepicker.parseDate("yy-mm-dd", dateString) : null;
}

export function dateToLocalString(date) {
    return date ? $.datepicker.formatDate("dd-mm-yy", date) : "";
}

export function dateFromLocalString(dateString) {
    return dateString ? $.datepicker.parseDate("dd-mm-yy", dateString) : null;
}

export default class PersonalPage extends Page {
    constructor() {
        super();

        this.ui = this.findElements({
            startpaginaAccount: {
                parent: ".auth-service-user",
                firstName: ".first-name",
                lastName: ".last-name",
                email: ".email",
                birthDate: ".birth-date",
            },
            gender: "input[name='gender']",
            birthDate: ".birth-date",
            newsletter: {
                parent: ".newsletter",
                checkboxWrapper: ".checkbox-wrapper",
                subscribed: "input[type='checkbox']",
                button: ".button.newsletter-button",
            },
        });


        this.on({
            "click .newsletter .newsletter-button": e => this.subscribeToNewsletter(e),
        });

        this.ui.birthDate.datepicker({
            prevText: pgettext("date picker", "Previous"),
            nextText: pgettext("date picker", "Next"),
            dateFormat: "dd-mm-yy",
            autoSize: true,
            closeText: pgettext("date picker", "Close"),
            changeMonth: true,
            constrainInput: true,
            dayNamesMin: dayNames,
            defaultDate: "-18y",
            firstDay: 1,
            maxDate: -1,
            monthNames: monthNames,
            monthNamesShort: monthNames,
            yearRange: "-100:+0",
            changeYear: true
        });

        ProfileAgent.on("change", (e, data) => {
            this.updateInfo(data);
            const loggedIn = ProfileAgent.isLoggedIn();

            // Temporary override to show input as readonly
            // Can be removed after SPR-3298
            if (loggedIn) {
                this.ui.startpaginaAccount.firstName.attr("readonly", true);
                this.ui.startpaginaAccount.lastName.attr("readonly", true);
                this.ui.startpaginaAccount.email.attr("readonly", true);
            }
        });
    }

    updateInfo(data) {
        this.ui.startpaginaAccount.firstName.val(data.first_name);
        this.ui.startpaginaAccount.lastName.val(data.last_name);
        this.ui.startpaginaAccount.email.val(data.email);
        this.ui.startpaginaAccount.birthDate.val(dateToLocalString(dateFromServerString(data.birth_date)));

        this.ui.gender.val([data.gender]);

        this.ui.newsletter.checkboxWrapper.toggle(data.newsletter);
        this.ui.newsletter.subscribed.prop("checked", data.newsletter);
        this.ui.newsletter.button.toggle(!data.newsletter);
    }

    validateSubscriber() {
        const data = this.getData(),
            errors = [];

        if (!data.email) {
            errors.push(gettext("Enter your email address"));
        } else if (data.email && !emailRegexp.test(data.email)) {
            errors.push(gettext("The email address is not complete"));
        }
        if (!data.first_name) {
            errors.push(gettext("Enter your first name"));
        }
        if (!data.last_name) {
            errors.push(gettext("Enter your last name"));
        }
        if (!data.gender) {
            errors.push(gettext("Please select 'Male' or 'Female'"));
        }
        if (errors.length) {
            showError(errors);
        }
        return errors.length === 0;
    }

    subscribeToNewsletter() {
        if (!this.validateSubscriber()) {
            return;
        }

        this.save({"newsletter": true}).then(() => {
            showMessage(gettext("Your have been successfully subscribed to the newsletter."));
        }, () => {
            showError(gettext(
                "An error occurred while subscribing to the newsletter. " +
                "Please try again later."
            ));
        });
    }

    getData() {
        return {
            first_name: this.ui.startpaginaAccount.firstName.val(),
            last_name: this.ui.startpaginaAccount.lastName.val(),
            email: this.ui.startpaginaAccount.email.val(),
            birth_date: dateToServerString(dateFromLocalString(this.ui.startpaginaAccount.birthDate.val())),
            gender: this.ui.gender.filter(":checked").val(),
            newsletter: this.ui.newsletter.subscribed.is(":checked"),
        };
    }
}
