import Alpine from "alpinejs";
import {AuthenticationWidget} from "authwidgets";
import "authwidgets/dist/authwidgets.css";
import $ from "jquery";

import "../rainbow/js/consent";
import "../rainbow/scss/application-tailwind.scss";
import getConfig from "../umbrella/js/config";
import "./js/account-button";
import DisplayPage from "./js/display";
import "./js/index";
import LinksPage from "./js/links";
import LocationPage from "./js/location";
import PersonalPage from "./js/personal";
import PrivacyPage from "./js/privacy";
import "./scss/_index.scss";

const pages = {
    ".personalpage": PersonalPage,
    ".linkpage": LinksPage,
    ".privacypage": PrivacyPage,
    ".locationpage": LocationPage,
    ".displaypage": DisplayPage,
};

$(function () {
    for (const selector of Object.keys(pages)) {
        if ($(selector).length) {
            new pages[selector]();
        }
    }

    const params = {
        "apiUrl": getConfig("authserviceApiUrl"),
        "recaptcha": {
            siteKey: getConfig("recaptchaSiteKey")
        },
        "loginUrl": getConfig("loginUrl"),
        "returnUrl": getConfig("emailVerificationUrl")
    };

    $(".login.row").each((idx, element) => {
        const authWidget = new AuthenticationWidget(element, params);
        authWidget.onLoginSucceeded = () => {
            let returnUrl = "/";
            if (window.location.hash.startsWith("#return=")) {
                returnUrl = decodeURIComponent(window.location.hash.replace("#return=", ""));
            }

            window.location = returnUrl;
        };

        authWidget.onRegistrationViewRequested = () => {
            window.location = getConfig("registrationUrl");
        };

        authWidget.showRegistrationLink();
    });
});

window.Alpine = Alpine;
Alpine.start();
